import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuthToken } from '../utils/firebaseUtils';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Typography,
  Snackbar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import './AccountPanel.css';

const AccountPanel = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [userDetails, setUserDetails] = useState({ email: '' });
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  useEffect(() => {
    fetchAccountData();
  }, []);

  // Fetch account data
  const fetchAccountData = async () => {
    setLoading(true);
    try {
      const token = await getAuthToken();
      const response = await axios.get('/api/api/account', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserDetails(response.data.userDetails);
      setSubscriptionInfo(response.data.subscriptionInfo);
    } catch (error) {
      console.error('Failed to fetch account data:', error);
      setError('Failed to load account data.');
    } finally {
      setLoading(false);
    }
  };

  // Cancel subscription
  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      const token = await getAuthToken();
      await axios.post(
        '/api/api/subscription/cancel',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSnackBarMessage('Subscription canceled successfully!');
      setSnackbarOpen(true);
      setSubscriptionInfo(null); // Clear subscription info after cancellation
    } catch (error) {
      console.error('Failed to cancel subscription:', error);
      setError('Failed to cancel subscription.');
    } finally {
      setLoading(false);
    }
  };

  // Update email
  const handleUpdateEmail = async () => {
    setLoading(true);
    try {
      const token = await getAuthToken();
      await axios.put(
        '/api/api/account/update-email',
        { email: newEmail },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSnackBarMessage('Email updated successfully!');
      setSnackbarOpen(true);
      setUserDetails((prev) => ({ ...prev, email: newEmail }));
      setEditDialogOpen(false);
    } catch (error) {
      console.error('Failed to update email:', error);
      setError('Failed to update email.');
    } finally {
      setLoading(false);
    }
  };

  // Request password reset
  const handlePasswordReset = async () => {
    setLoading(true);
    try {
      const token = await getAuthToken();
      await axios.put(
        '/api/api/account/update-password',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSnackBarMessage('Password reset link sent to your email!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to send password reset link:', error);
      setError('Failed to send password reset link.');
    } finally {
      setLoading(false);
    }
  };

  // Snackbar close handler
  const handleSnackbarClose = () => setSnackbarOpen(false);

  return (
    <Container maxWidth="md" className="account-panel">
      <Typography variant="h4" gutterBottom>
        Account Management
      </Typography>

      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}

      {/* User Details */}
      <Paper className="section-container">
        <Box className="section-header">
          <Typography variant="h6">Account Details</Typography>
          <IconButton onClick={() => setEditDialogOpen(true)}>
            <EditIcon />
          </IconButton>
        </Box>
        <Typography>Email: {userDetails.email}</Typography>
        <Button variant="contained" color="secondary" onClick={handlePasswordReset}>
          Change Password
        </Button>
      </Paper>

      {/* Subscription Information */}
      {subscriptionInfo ? (
        <Paper className="section-container">
          <Typography variant="h6">Subscription Details</Typography>
          <Typography>Plan: {subscriptionInfo.plan}</Typography>
          <Typography>
            Monthly Cost: ${(subscriptionInfo.amount / 100).toFixed(2)}
          </Typography>
          <Typography>
            Next Billing Date:{' '}
            {new Date(subscriptionInfo.nextBillingDate * 1000).toLocaleDateString()}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancelSubscription}
          >
            Cancel Subscription
          </Button>
        </Paper>
      ) : (
        <Paper className="section-container">
          <Typography variant="h6">No Active Subscription</Typography>
          <Typography>
            Explore the benefits of becoming a member and enjoy exclusive perks!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="/checkout/"
          >
            View Membership Perks
          </Button>
        </Paper>
      )}

      {/* Edit Email Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Update Email</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="New Email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateEmail} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackBarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default AccountPanel;