// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import CheckoutPage from './pages/CheckoutPage';
import ProtectedRoute from './components/ProtectedRoute';
import RegisterPage from './pages/RegisterPage';
import SuccessPage from './pages/SuccessPage';
import VerifyEmail from './pages/VerifyEmail';
import ResetPassword from './pages/ResetPassword';
import AdminPanel from './pages/AdminPanel';
import AccountPanel from './pages/AccountPanel';
import TermsOfService from './pages/TermsOfService';
import AboutUs from './pages/AboutUs';
import { AuthProvider } from './context/AuthContext';
import Footer from './components/Footer';
import Header from './components/Header';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Help from './pages/Help';
import './App.css';


function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <div className="content">
            <Header />
            <Routes>
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminPanel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <AccountPanel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/help"
              element={
                <ProtectedRoute>
                  <Help />
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/checkout"
              element={
                <ProtectedRoute>
                  <CheckoutPage />
                </ProtectedRoute>
              }
            />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
          </Routes>
          </div>
          <footer id="footer">
            <Footer />
          </footer>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;