// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';

const firebaseConfig = {
    apiKey: "AIzaSyAtkIpnsYdGvvEGZV6J5OWJLGdN02_7azg",
    authDomain: "waffle-island.firebaseapp.com",
    projectId: "waffle-island",
    storageBucket: "waffle-island.appspot.com",
    messagingSenderId: "525546843485",
    appId: "1:525546843485:web:2e90343baee3b0e84a8cb4",
    measurementId: "G-EJLNHF589J"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const stripePromise = loadStripe('pk_live_51PW7NFC9d2e8ToFdliLuAARdRIpCR0naaqb6ylVK2GTBPGFoozbkIFVoi3IvMuNXAtH5FCKSZEuKY1HKKRku5RHG00NJt5zEuw');