import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getAuthToken } from '../utils/firebaseUtils';
import './ResetPassword.css';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      setMessage('Invalid or missing token. Please request a new password reset.');
    }
  }, [token]);

  const handlePasswordReset = async () => {
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    try {
      // Get the JWT token
      const jwtToken = await getAuthToken();

      // Send password reset request
      const response = await axios.post(
        '/api/api/reset-password',
        { token, password }, // Send token from URL and new password
        { headers: { Authorization: `Bearer ${jwtToken}` } } // Include JWT token in headers
      );

      setMessage(response.data.message);
      setSuccess(true);

      setTimeout(() => {
        navigate('/'); // Redirect to the home page or login page
      }, 4000);
    } catch (error) {
      setMessage(error.response ? error.response.data.message : 'Failed to reset password. Please try again.');
    }
  };

  return (
    <div className="reset-container">
      <div className="reset-card">
        <h2>{success ? 'Password Reset Successful' : 'Reset Your Password'}</h2>
        {message && <p className="reset-message">{message}</p>}

        {!success && token && (
          <div className="reset-form">
            <input
              type="password"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="reset-input"
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="reset-input"
            />
            <button onClick={handlePasswordReset} className="reset-button">
              Reset Password
            </button>
          </div>
        )}
        {success && <p className="success-message">Redirecting to the home page...</p>}
      </div>
    </div>
  );
};

export default ResetPassword;