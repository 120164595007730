import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuthToken } from '../utils/firebaseUtils';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import './AccountPanel.css';

const AccountPanel = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [billingInfo, setBillingInfo] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: '',
    username: '',
  });

  useEffect(() => {
    fetchAccountData();
  }, []);

  // Fetch account and billing data
  const fetchAccountData = async () => {
    setLoading(true);
    try {
      const token = await getAuthToken();
      const response = await axios.get('/api/account', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBillingInfo(response.data.billingInfo);
      setSubscriptionInfo(response.data.subscriptionInfo);
      setUserDetails(response.data.userDetails);
    } catch (error) {
      console.error('Failed to fetch account data:', error);
      setError('Failed to load account data.');
    } finally {
      setLoading(false);
    }
  };

  // Update account details
  const handleUpdateAccount = async () => {
    setLoading(true);
    try {
      const token = await getAuthToken();
      await axios.put(
        '/api/account/update',
        { email: userDetails.email, username: userDetails.username },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSnackBarMessage('Account updated successfully!');
      setSnackbarOpen(true);
      setEditDialogOpen(false);
    } catch (error) {
      console.error('Failed to update account details:', error);
      setError('Failed to update account details.');
    } finally {
      setLoading(false);
    }
  };

  // Snackbar close handler
  const handleSnackbarClose = () => setSnackbarOpen(false);

  return (
    <Container maxWidth="md" className="account-panel">
      <Typography variant="h4" gutterBottom>
        Account Management
      </Typography>

      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}

      {/* User Details */}
      <Paper className="section-container">
        <Box className="section-header">
          <Typography variant="h6">Account Details</Typography>
          <IconButton onClick={() => setEditDialogOpen(true)}>
            <EditIcon />
          </IconButton>
        </Box>
        <Typography>Email: {userDetails.email}</Typography>
        <Typography>Username: {userDetails.username}</Typography>
      </Paper>

      {/* Billing Information */}
      <Paper className="section-container">
        <Typography variant="h6">Billing Information</Typography>
        {billingInfo ? (
          <>
            <Typography>Card: **** **** **** {billingInfo.last4}</Typography>
            <Typography>Expiration: {billingInfo.expiration}</Typography>
            <Typography>Billing Address: {billingInfo.address}</Typography>
            <Button
              startIcon={<CreditCardIcon />}
              variant="contained"
              className="update-button"
              onClick={() => console.log('Redirect to billing update')}
            >
              Update Billing Info
            </Button>
          </>
        ) : (
          <Typography className="no-info-text">No billing information available.</Typography>
        )}
      </Paper>

      {/* Subscription Information */}
      <Paper className="section-container">
        <Typography variant="h6">Subscription Details</Typography>
        {subscriptionInfo ? (
          <>
            <Typography>Plan: {subscriptionInfo.plan}</Typography>
            <Typography>Status: {subscriptionInfo.status}</Typography>
            <Typography>Next Billing Date: {subscriptionInfo.nextBillingDate}</Typography>
            <Button
              startIcon={<PersonIcon />}
              variant="contained"
              className="update-button"
              onClick={() => console.log('Redirect to subscription management')}
            >
              Manage Subscription
            </Button>
          </>
        ) : (
          <Typography className="no-info-text">No active subscription found.</Typography>
        )}
      </Paper>

      {/* Edit Account Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Account Details</DialogTitle>
        <DialogContent className="dialog-content">
          <TextField
            fullWidth
            label="Email"
            value={userDetails.email}
            onChange={(e) => setUserDetails((prev) => ({ ...prev, email: e.target.value }))}
          />
          <TextField
            fullWidth
            label="Username"
            value={userDetails.username}
            onChange={(e) => setUserDetails((prev) => ({ ...prev, username: e.target.value }))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateAccount} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackBarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        className="snackbar-success"
      />
    </Container>
  );
};

export default AccountPanel;