import React, { useState } from 'react';
import './Header.css';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div id="nav">
            <a href="https://dev.waffleisland.co/">
                <img src="https://dev.waffleisland.co/assets/graphics/logo.svg" alt="Waffle Island Logo" />
            </a>
            <div className="menu-icon" onClick={toggleMenu}>
                &#9776; Menu
            </div>
            <ul className={menuOpen ? "nav-menu open" : "nav-menu"}>
                <li><a href="https://dev.waffleisland.co/help">Help</a></li>
                <li><a href="https://dev.waffleisland.co/store.html">Store</a></li>
                <li><a href="https://waffleisland223.wordpress.com/">Blog</a></li>
                <li><a href="https://dev.waffleisland.co/checkout/">VIP</a></li>
                <li><a href="https://dev.waffleisland.co/about">About</a></li>
                <li><a href="https://dev.waffleisland.co/">Home</a></li>
                <li><a href="https://dev.waffleisland.co/account">Account</a></li>
            </ul>
        </div>
    );
};

export default Header;